/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl, appConfigs } from "../../../../_chamasoft/_helpers";
import { ContentRoute } from "../../../../_chamasoft/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ConfirmCode from "./ConfirmCode";
import EntityType from "./EntityType";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import VerifyAccount from "./VerifyAccount";
import PendingVerification from "./PendingVerification";
import Checkin from "./Checkin";
import CsBank from "./CsBank";
import CsNgo from "./CsNgo";
import "../../../../_chamasoft/_assets/sass/pages/login/chamasoft.scss";
import { FormattedMessage } from "react-intl";
import { useLocation } from 'react-router-dom';
import SVG from "react-inlinesvg";
import queryString from 'query-string';
import VerifyInstitution from "./VerifyInstitution";
import Join from "./Join";

export function AuthPage() {
  const getParams = queryString.parse(window.location.search);
  const fiLayout = getParams?.for === 'fi' || false;

  const current_year = new Date().getFullYear();
  const curr_path = useLocation().pathname;
  let extracted_path = curr_path.split('/');
  extracted_path = extracted_path.slice(0, (extracted_path.length - 1));
  extracted_path = extracted_path.join("/");
  // console.log(curr_path)
  // console.log(extracted_path)
  const urlsWithFullLayout = [
    "/auth/bank-options",
    "/auth/ngo-options",
    "/auth/pending",
    "/auth/authorize",
    "/auth/options",
    "/auth/join",
  ];
  const urlsWithCenteredlLayout = [
    "/auth/options",
    "/auth/join",
  ];

  let isFullLayout = urlsWithFullLayout.includes(curr_path) || urlsWithFullLayout.includes(extracted_path);
  let isCenteredLayout = urlsWithCenteredlLayout.includes(curr_path) || urlsWithCenteredlLayout.includes(extracted_path);

  function getAsideTemplate() {
    return !isFullLayout ?
      <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ backgroundImage: `url(${toAbsoluteUrl(fiLayout ? "/media/bg/for-fi.jpg" : "/media/bg/auth_bg_1.jpg")})` }}>
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            {fiLayout ? (<h1 className="font-size-h1 font-weight-light mb-5 text-white">
              Chamasoft for<br /><strong className="font-weight-boldest">financial institutions</strong>
            </h1>) : (<h1 className="font-size-h1 font-weight-light mb-5 text-white">
              Partner with us <strong className="font-weight-boldest">today</strong> &amp; <br />
              offer group services better as <br />
              we handle your financial<br />
              administration.
            </h1>)}
            {fiLayout && (<>
              <div className="row py-5 pt-md-10 opacity-80">
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Bank Insights</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Get insights on savings groups and start mobilizing low-cost deposits.
                    </span>
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Know your customers</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Chamasoft has a bank-managed user interface that provides real time full reports.
                    </span>
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Low-cost Deposit</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Make informed lending decisions while mobilizing cheap deposits from members.
                    </span>
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Risk Assessment</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Chamasoft helps you understand the risk and return on investment on customer deposits.
                    </span>
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Advanced Tools</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Onboard groups remotely, open accounts, start depositing, and get recommendations on lending decisions based on group data.
                    </span>
                  </p>
                </div>
                <div className="col-md-5">
                  <p className="font-weight-light mb-5 text-white">
                    <strong className="font-size-h6 font-weight-boldest">Group Deposits</strong>
                    <span className="font-weight-light text-white opacity-90 d-block">
                      Get insights to the bank on behavior on their savings group and investment club partners
                    </span>
                  </p>
                </div>
              </div>
            </>)}
          </div>
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold- text-white"><small>&copy; {current_year} {appConfigs.org_name}</small></div>
            <div className="d-flex">
              {/* <Link to="/privacy" className="text-white"><FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" /></Link>
              <Link to="/contact" className="text-white ml-10"><FormattedMessage id="AUTH.FOOTER.LINKS.CONTACT" /></Link> */}
            </div>
          </div>
        </div>
      </div>
      : null
  }

  function getHeaderBackLink() {
    if (isFullLayout && !isCenteredLayout) {
      return (
        <div className="position-absolute top-0 left-0 text-right- mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center- py-5 px-10">
          <Link to="/auth" className="font-weight-bold ml-2" id="kt_login_signup" title="Go back to log in">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
            </span>
          </Link>
        </div>
      );
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className={"login " + (isCenteredLayout ? 'login-2' : 'login-1') + " chamasoft-login login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white " + (isFullLayout ? ' bg-light' : '')} id="kt_login">
          {getAsideTemplate()}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden-">
            {getHeaderBackLink()}
            {/*begin::Content header*/}
            <div className="chamasoft-effect" style={{ background: `url(${toAbsoluteUrl("/media/bg/cs_auth_grad.svg")}) top center no-repeat rgba(255, 255, 255, 0)`, backgroundSize: 'cover' }}></div>
            {/*end::Content header*/}
            <div className={"flex-column-auto flex-column flex-sm-row justify-content-between align-items-center my-5 " + (isFullLayout ? ' _full-layout d-none' : ' d-flex')}>
              <Link to="/">
                <img src={`${toAbsoluteUrl("/media/logos/chamasoft_new_logo.png")}`} alt="chamasoft" style={{ height: '36px', marginLeft: '-6px' }} />
              </Link>
            </div>
            {/* begin::Content body */}
            <div className={"d-flex flex-column-fluid " + (isCenteredLayout ? 'flex-center' : 'flex-center-') + " " + (isCenteredLayout ? 'align-items-start' : 'align-items-center') + " mt-30- mt-lg-0"}>
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/signup" component={Registration} />
                <ContentRoute path="/auth/forgot-password/:type" component={ForgotPassword} />
                <ContentRoute path="/auth/verify-account/:hex" component={VerifyAccount} />
                <ContentRoute path="/auth/confirm-code" component={ConfirmCode} />
                <ContentRoute path="/auth/confirm-code/:type" component={ConfirmCode} />
                <ContentRoute path="/auth/entity-type" component={EntityType} />
                <ContentRoute path="/auth/bank-options" component={CsBank} />
                <ContentRoute path="/auth/ngo-options" component={CsNgo} />
                <ContentRoute path="/auth/reset-password/:token" component={ResetPassword} />
                <ContentRoute path="/auth/verify-email-request" component={VerifyEmail} />
                <ContentRoute path="/auth/verify-email/:token" component={VerifyEmail} />
                <ContentRoute path="/auth/pending" component={PendingVerification} />
                <ContentRoute path="/auth/options" component={Checkin} />
                <ContentRoute path="/auth/join/:hex" component={Join} />
                <ContentRoute path="/auth/authorize" component={VerifyInstitution} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className={"mt-5 py-5 " + (isFullLayout ? (isCenteredLayout ? ' d-flex flex-center' : ' _full-layout') : '')}>
              <div className="d-flex flex-column-auto flex-column flex-sm-row justify-content-between- align-items-center">
                <small><Link to="/" className="text-info text-hover-primary mr-5 pr-lg-5"><FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" /></Link></small>
                <small><a href="https://chamasoft.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer" className="text-info text-hover-primary mr-5 pr-lg-5"><FormattedMessage id="AUTH.FOOTER.LINKS.TERMS" /></a></small>
                <small><a href="https://calendly.com/chamasoft-meetings/support-meeting" target="_blank" rel="noopener noreferrer" className="text-info text-hover-primary"><FormattedMessage id="AUTH.FOOTER.LINKS.SCHEDULE" /></a></small>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
