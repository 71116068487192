import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
// Reference: https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import { appConfigs } from "../../../../_chamasoft/_helpers";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// Library references: https://www.npmjs.com/package/material-ui-phone-number
import MuiPhoneInput from "material-ui-phone-number";
import queryString from 'query-string';

const initialValues = {
  fullname: "",
  email: "",
  phone: "",
  password: "",
  acceptTerms: false,
};

function Registration(props) {
  document.title = "Sign up — " + appConfigs.app_name;
  const { intl } = props;
  const alert = props.history.location.state?.alert;
  const getParams = queryString.parse(window.location.search);
  const fiLayout = getParams?.for === 'fi' || false;
  const [showPassword, setshowPasswordValue] = useState(false);
  const [_phone, setPhone] = useState(initialValues.phone);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.useRef();
  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Full name', min: '3' }))
      .max(100, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Full name', max: '100' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Full name' })),
    email: Yup.string()
      .email("Wrong email format")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Email' }))
      .min(5, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Email', min: '5' }))
      .max(250, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Email', max: '250' })),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Phone' })),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Password', min: '8' }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Password', max: '50' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Password' })),
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: "AUTH.VALIDATION.AGREEMENT_REQUIRED", },)),
  });

  const handleClickShowPassword = () => {
    setshowPasswordValue(!showPassword);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const randomStr = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      var navigateTo = '';
      var navigateAlert = {};
      var names = values.fullname.split(" ");

      if (names.length < 2) {
        setStatus({ msg: intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Full name' }), type: 'danger' });
        setSubmitting(false);
        return;
      }

      var firstname = names[0];
      var middlename = names[1];
      var lastname = names.length > 2 ? names[1] + " " + names[2] : names[1];

      setSubmitting(true);
      enableLoading();

      const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        // console.log("Captcha value:", token);
        if (!token) {
          return false;
        }
        return true;
      };

      if (!onSubmitWithReCAPTCHA()) {
        setSubmitting(false);
        setStatus({ msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR", }), type: 'danger' });
        disableLoading();
        return;
      }

      register(values.email, values.fullname, firstname, middlename, lastname, values.password, _phone)
        .then((resp) => {
          // console.log(resp.data);
          let verStr = randomStr(40);
          if (resp.data.result_code === 1) {
            if (!resp.data.is_exist) {
              // Register success
              navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.SUCCESS", }, { phone: resp.data.data.phone }), type: 'success' };
              // props.login(accessToken);
              localStorage.setItem('accountVerifyHex', verStr);
              navigateTo = "/auth/verify-account/" + verStr;
            } else {
              if (!resp.data.is_validated) {
                navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.ACCOUNT_EXISTS_UNVERIFIED", }), type: 'info' };
                localStorage.setItem('accountVerifyHex', verStr);
                navigateTo = "/auth/verify-account/" + verStr;
              } else {
                navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.ACCOUNT_EXISTS_VERIFIED", }), type: 'info' };
                navigateTo = fiLayout ? "/auth/login?for=fi" : "/auth/login";
              }
            }
          } else {
            setStatus({ msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR", }), type: 'danger' });
          }
          disableLoading();
          setSubmitting(false);
        }).catch(() => {
          setSubmitting(false);
          setStatus({ msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR", }), type: 'danger' });
          disableLoading();
        }).finally(() => {
          if (navigateTo !== '') props.history.push(navigateTo, { alert: navigateAlert });
        });
    },
  });

  const inputHasError = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return true;
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return false;
    }

    return false;
  };

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {/* begin::Head */}
      <div className="text-left mb-5 mb-lg-20-">
        <h3 className="font-weight-bolder mb-0"><FormattedMessage id="AUTH.REGISTER.TITLE" /></h3>
        <p className="text-dark-50 font-weight-normal"><FormattedMessage id="AUTH.REGISTER.DESCR" /></p>
      </div>
      {/* end::Head */}

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status ? (
          <div className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{formik.status.msg}</div>
          </div>
        ) : alert ? (
          <div className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{alert.msg}</div>
          </div>
        ) : null}
        {/* end: Alert */}

        {/* begin: Handle reCaptcha */}
        <ReCAPTCHA
          sitekey={appConfigs.recaptcha_site_key}
          size="invisible"
          ref={recaptchaRef}
        />
        {/* end: Handle reCaptcha */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container mb-7">
          <TextField
            error={inputHasError("fullname")}
            label="Full name"
            name="fullname"
            type="text"
            autoComplete="name"
            {...formik.getFieldProps("fullname")}
            margin="normal"
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Phone */}
        <div className="form-group fv-plugins-icon-container mb-1">
          <input type="hidden" name="phone" value={_phone} {...formik.getFieldProps("phone")} />
          <MuiPhoneInput
            error={inputHasError("phone")}
            label="Phone"
            name="_phone"
            value={_phone}
            countryCodeEditable={true}
            inputClass={'cs-phone-inp'}
            defaultCountry='ke'
            autoComplete="off"
            onChange={setPhone}
            onKeyUp={(e) => formik.setFieldValue('phone', e.target.value)}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
        {/* end: Phone */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container mb-1">
          <TextField
            error={inputHasError("email")}
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            {...formik.getFieldProps("email")}
            margin="normal"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container mb-2-">
          <TextField
            error={inputHasError("password")}
            label="New password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            {...formik.getFieldProps("password")}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group mt-2">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span></span> &nbsp;&nbsp;
            I agree to the &nbsp;
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </Link>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex justify-content-between align-items-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              // !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
          >
            <span><FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" /></span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link to={fiLayout ? "/auth/login?for=fi" : "/auth/login"}>
            <button
              type="button"
              className="btn btn-outline-secondary btn-pill btn-sm font-weight-bold px-15 py-4"
            >
              <FormattedMessage id="AUTH.GENERAL.LOGIN" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
