import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getCountries, ngoSignup } from "../_redux/authCrud";
import { Redirect } from "react-router";
import { appConfigs } from "../../../../_chamasoft/_helpers";
// import { countries } from "../../../../_chamasoft/_assets/countries";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiPhoneInput from "material-ui-phone-number";

const initialValues = {
  phone: "",
  email: "",
  country: "",
  name: "",
  address: "",
};

function CsNgo(props) {
  document.title = "NGO options — " + appConfigs.app_name;
  const { intl } = props;
  var [alert, setAlert] = useState(null); //useState(props.history.location.state?.alert);
  let user = props.history.location.state?.user;
  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  const [fetchingDataStatus, setFetching] = useState(true);

  const [countries, setCountries] = useState([]);
  const [countryIdObj, setCountryIdObj] = useState(null);

  useEffect(() => {
    if (fetchingDataStatus) {
      getCountries('cs-bank').then(res => {
        let newArr = res.data.map((el) => {
          return { _id: el._id, code: el.code, label: el.label };
        });
        setCountries(newArr);
      }).catch(err => {
        setCountries([]);
      }).finally(() => {
        setFetching(false);
      });
    }
  }, [fetchingDataStatus, user.access_token]);

  const isValid = () => {
    // return true;
    if (!user || user === undefined || user === null || user === {}) return false;
    else return true;
  };

  const CsNgoSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Name' })),
    email: Yup.string()
      .email("Wrong email format")
      .min(5, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Email', min: '5' }))
      .max(250, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Email', max: '250' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Email' })),
    country: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Country' })),
    address: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Address' })),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Phone' })),
  });

  const inputHasError = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) return true;
    if (formik.touched[fieldname] && !formik.errors[fieldname]) return false;
    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CsNgoSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values); //return;
      var token = '';
      var navigateTo = '';
      var navigateAlert = {};
      setSubmitting(true);
      ngoSignup((user?.access_token || ''), values.name, countryIdObj, values.email, values.website, values.address, values.phone)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data.result_code === 1) {
            token = resp.data.redirect_string || '';
            // let _host = localStorage.getItem('_back') ? localStorage.getItem('_back') : 'https://banks.chamasoft.com';
            if (resp.data.ngo.is_validated === 0) {
              navigateTo = "/auth/pending";
            } else if (resp.data.ngo.is_validated === 1) {
              // window.location.href = _host + '/auth/verify-user/' + token;
              navigateTo = "/auth/options";
            } else {
              navigateTo = "/auth/login";
              navigateAlert = { msg: "Sorry, we can't allow you to login at this point. Kindly reach out to us on +254733366240 for assistance.", type: 'danger' };
            }
            // localStorage.removeItem('_back');
          } else if (resp.data.result_code === 2 || resp.data.result_code === 7) {
            navigateAlert = { msg: "Your session has expired, log in again to continue.", type: 'danger' };
            navigateTo = "/auth/login";
          } else {
            // navigateAlert = { msg: intl.formatMessage({ id: "AUTH.PAIR.ACCOUNT.ERROR", }), type: 'danger' };
            setAlert({ msg: resp.data.message, type: 'danger' });
            navigateTo = "";
            // navigateTo = "/auth/login";
          }
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(intl.formatMessage({ id: "AUTH.PAIR.ACCOUNT.ERROR", },));
        }).finally(() => {
          setSubmitting(false);
          if (navigateTo !== '') props.history.push(navigateTo, { alert: navigateAlert, token: token });
        });
    },
  });

  const renderFetchData = () => {
    return fetchingDataStatus ? (
      <div className="">
        <svg className="splash-spinner" viewBox="0 0 50 50" style={{ marginLeft: "0px" }}>
          <circle className="path" cx="25" cy="25" r="10" fill="none" strokeWidth="2"></circle>
        </svg>
      </div>
    ) : (<></>);
  };

  return (
    <>
      {!isValid() && <Redirect to="/auth" />}
      {isValid() && (
        <div className="login-form bank-options" style={{ display: "block" }}>
          {/* begin::Head */}
          <div className="text-left mb-5 mb-lg-20- text-primary">
            <h1 className="font-weight-normal mb-0" style={{ fontSize: "36px" }}>Hello, <strong className="font-weight-bolder">{user?.first_name}</strong></h1>
            <h5 className="font-weight-normal mb-0">We're excited to have you and your NGO join us, <br />welcome aboard.</h5>
          </div>
          <div className="text-left mb-0 mt-5">
            <p className="text-dark-50 font-weight-normal">Before we continue, fill in the details below.</p>
          </div>
          {/* end::Head */}
          <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            {formik.status ? (
              <div className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
                <button type="button" className="close" onClick={() => setAlert(null)}><span aria-hidden="true">×</span><span className="sr-only">Close alert</span></button>
                <div className="alert-text font-weight-bold">{formik.status.msg}</div>
              </div>
            ) : alert ? (
              <div className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
                <button type="button" className="close" onClick={() => setAlert(null)}><span aria-hidden="true">×</span><span className="sr-only">Close alert</span></button>
                <div className="alert-text font-weight-bold">{alert.msg}</div>
              </div>
            ) : null}
            {renderFetchData()}
            <div className={"row " + (fetchingDataStatus ? "d-none" : "")}>
              <div className="col-md-12">
                <div className="form-group fv-plugins-icon-container mb-2 mt-5">
                  <Autocomplete
                    value={countryIdObj}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setCountryIdObj(newValue);
                        formik.setFieldValue('country', newValue?._id);
                      }
                    }}
                    id="country"
                    options={countries}
                    style={{ width: '100%' }}
                    getOptionLabel={(option, value) => (option && option.label) ? option.label : ''}
                    getOptionSelected={(option, value) => {
                      if (value === "") {
                        return true;
                      } else if (value._id === option._id) {
                        return true;
                      }
                    }}
                    blurOnSelect
                    clearOnEscape
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.label} ({option.code})
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        name={'country'}
                        value={countryIdObj}
                        {...params}
                        error={inputHasError("country")}
                        // {...formik.getFieldProps("country")}
                        label="Country of operation"
                        helperText="Select NGO's country of operation"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className={"form-group fv-plugins-icon-container mb-0 mt-0"}>
                  <TextField
                    error={inputHasError("name")}
                    label="Organization name"
                    name="name"
                    type="text"
                    {...formik.getFieldProps("name")}
                    margin="normal"
                    helperText="Organization name, in full"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className={"form-group fv-plugins-icon-container mb-0 mt-2"}>
                  <input type="hidden" name={'phone'} value={formik.values.phone} {...formik.getFieldProps('phone')} />
                  <MuiPhoneInput
                    label="Organization phone"
                    name={'_phone'}
                    value={formik.values.phone}
                    countryCodeEditable={false}
                    inputClass={'cs-phone-inp'}
                    defaultCountry={((countryIdObj?.code) ? (countryIdObj?.code).toLowerCase() : 'ke') || 'ke'}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onKeyUp={(e) => formik.setFieldValue('phone', e.target.value)}
                    error={inputHasError("phone")}
                    helperText="Organization phone"
                  // disabled={savingData}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className={"form-group fv-plugins-icon-container mb-2 mt-0"}>
                  <TextField
                    error={inputHasError("email")}
                    label="Organization email"
                    name="email"
                    type="email"
                    {...formik.getFieldProps("email")}
                    margin="normal"
                    helperText="Email address for the organization"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className={"form-group fv-plugins-icon-container mb-0 mt-0"}>
                  <TextField
                    error={inputHasError("address")}
                    label="Organization address"
                    name="address"
                    type="text"
                    {...formik.getFieldProps("address")}
                    margin="normal"
                    helperText="Postal address, in full"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center pt-15">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                    disabled={formik.isSubmitting}
                  >
                    <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                    {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(CsNgo));
