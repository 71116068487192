import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { appConfigs } from "../../../../_chamasoft/_helpers";

function PendingVerification(props) {
  document.title = "Pending verification — " + appConfigs.app_name;
  // const { intl } = props;

  return (
    <>
      <div className="login-form bank-options" style={{ display: "block" }}>
        <div className="text-left mb-5 mb-lg-20- text-primary">
          <h1 className="font-weight-normal mb-2" style={{ fontSize: "36px" }}>Hello,</h1>
          <h5 className="font-weight-normal mb-0">We're reviewing your application. One of our representatives will get back to you within <strong className="font-weight-bolder">48 hours</strong>.</h5>
        </div>
        <div className="text-left mb-0 mt-5">
          <p className="text-dark-50 font-weight-normal">If this takes longer than the stipulated time,<br />give us a call <strong>+254 733 366 240</strong></p>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(PendingVerification));
