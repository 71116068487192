import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { appConfigs, toAbsoluteUrl } from "../../../../_chamasoft/_helpers";
import SVG from "react-inlinesvg";
import { getCheckin, checkinUser } from "../_redux/authCrud";
import { Link } from "react-router-dom";
import "../../../../checkin.scss"
import { UserProfileDropdown } from "../../../../_chamasoft/layout/components/extras/dropdowns/UserProfileDropdown";

function Checkin(props) {
  document.title = "Options — " + appConfigs.app_name;
  let user = props.history.location.state?.user || {};
  var [alert, setAlert] = useState(null);
  if (!user?.access_token) props.history.replace('/auth/login', { alert: { msg: 'Sorry, the requested page is not available. Log in to continue.', type: 'danger' } });
  // console.log(user);
  // const { intl } = props;
  const [fetchingDataStatus, setFetchingDataStatus] = useState(true);
  const [checkinData, setCheckinData] = useState([]);

  useEffect(() => {
    var navigateAlert = {};
    var navigateUrl = '';
    getCheckin(user.access_token).then(res => {
      if (res.data.result_code === 1) {
        setCheckinData(res.data.profiles);
      } else {
        navigateAlert = { msg: "An error occurred. We could not fetch required data, please try again.", type: "danger" };
      }
    }).catch(err => {
      navigateAlert = { msg: "An error occurred. We could not fetch required data, please try again.", type: "danger" };
    }).finally(() => {
      setFetchingDataStatus(false);
      if (navigateUrl !== '') props.history.replace('/auth/login', { alert: navigateAlert });
    });
  }, [props.history, user.access_token]);

  const loadProfile = profile => {
    setFetchingDataStatus(true);
    checkinUser(user.access_token, profile).then(res => {
      if (res.data.result_code === 1) {
        props.login(res.data.user.access_token);
      } else {
        setAlert({ msg: "Sorry, we could not load the requested option, please try again.", type: "danger" });
        setFetchingDataStatus(false);
      }
    }).catch(err => {
      setAlert({ msg: "Sorry, we could not load the requested option, please try again.", type: "danger" });
      setFetchingDataStatus(false);
    });
  }

  return (
    <>
      <div className="login-form">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end pb-10">
            <UserProfileDropdown user={user} />
          </div>
          <div className="col-md-12">
            <div className=" text-center">
              <Link to="/">
                <img className="d-block mx-auto mb-4" src={`${toAbsoluteUrl("/media/logos/chamasoft_new_logo.png")}`} alt="chamasoft" style={{ height: '36px', marginLeft: '-6px' }} />
              </Link>
              <h4 className="_title m-0">Chamasoft <span>Options</span></h4>
              <p className="_title-descr m-0">Choose to continue</p>
            </div>

            {alert ? (
              <div className={`my-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
                <button type="button" className="close" onClick={() => setAlert(null)}><span aria-hidden="true">×</span><span className="sr-only">Close alert</span></button>
                <div className="alert-text font-weight-bold">{alert.msg}</div>
              </div>
            ) : null}

            <div className="row py-5">
              <div className="col-md-12">
                <Link to={{ pathname: "/auth/entity-type", state: { token: user?.access_token } }} className={"btn btn-pill- btn-primary btn-block " + (fetchingDataStatus ? 'disabled' : '')}>
                  <strong>Register</strong> Bank, NGO, SACCO, Chama &amp; More
                  <span className="_indico svg-icon svg-icon-md ml-2">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")} />
                  </span>
                </Link>
              </div>
            </div>

            {fetchingDataStatus && <div className="d-flex flex-center">
              <svg className="splash-spinner" viewBox="0 0 50 50" style={{ marginLeft: "-25px" }}>
                <circle className="path" cx="25" cy="25" r="10" fill="none" strokeWidth="2"></circle>
              </svg>
            </div>}

            {(!fetchingDataStatus && checkinData.length > 0) && <ul className="list-group mb-3 resources hoverable">
              {checkinData.map((profile, i) => {
                if (profile.is_validated !== 2) {
                  return <li className={"list-group-item d-flex justify-content-between " + (profile.is_validated === 0 ? 'disabled' : '')} key={'profile-' + i} onClick={() => loadProfile(profile)}>
                    <div>
                      <span id="resource_img" className="svg-icon svg-icon-xl svg-icon-dark-50">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                      </span>
                      <h6 className="my-0 _group">{profile.name}</h6>
                      <small className="_role"><strong>{profile.profile === 'cs-bank' ? 'Bank' : 'NGO'}</strong>, {(profile.type === 'user' ? 'User' : profile.type === 'agent' ? 'Agent' : profile.is_owner === 1 ? (profile.role?.name ? profile.role?.name : 'System User') : 'System User')}</small>
                      {(profile.is_validated === 0) && <small className="_role text-danger">Pending verification</small>}
                    </div>
                    <span className="_indico svg-icon svg-icon-xl svg-icon-dark-50">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")} />
                    </span>
                  </li>
                } else return <></>
              })}
            </ul>}

            {(!fetchingDataStatus && checkinData.length === 0) && <div className="text-center my-5">
              <h6 className="d-block text-dark-50 font-weight-bolder">No options</h6>
              <small className="d-block text-dark-50">Your Banks, NGOs, Chamas, SACCOs and more will<br />appear here when available.</small>
            </div>}

          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Checkin));