import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { appConfigs } from "../../../../_chamasoft/_helpers";
import queryString from 'query-string';
import { authorizeInstitution } from "../_redux/authCrud";

function VerifyInstitution(props) {
  document.title = "Verifying institution — " + appConfigs.app_name;
  // const { intl } = props;
  const getParams = queryString.parse(window.location.search);
  //../auth/authorize?e=fi&t=erRtQDuYh&a=approve
  const entity = getParams?.e || "";
  const token = getParams?.t || "";
  const action = getParams?.a || "";
  // console.log("Entity: " + entity + ", Token: " + token + ", Action: " + action);

  useEffect(() => {
    var navigateAlert = {};
    authorizeInstitution(entity, token, action).then(res => {
      if (res.data.result_code === 1) {
        navigateAlert = { msg: "The " + (entity === 'ngo' ? 'NGO' : 'financial institution') + " was successfully " + ((action === 'approve') ? 'approved' : 'rejected'), type: "success" };
      } else {
        navigateAlert = { msg: "An error occurred. The " + (entity === 'ngo' ? 'NGO' : 'financial institution') + " could not be " + ((action === 'approve') ? 'approved' : 'rejected'), type: "danger" };
      }
    }).catch(err => {
      navigateAlert = { msg: "An error occurred. The " + (entity === 'ngo' ? 'NGO' : 'financial institution') + " could not be " + ((action === 'approve') ? 'approved' : 'rejected'), type: "danger" };
    }).finally(() => {
      props.history.push("/auth/login", { alert: navigateAlert });
    });
  });

  return (
    <>
      <div className="login-form bank-options" style={{ display: "block-" }}>
        <div className="text-left mb-0 text-primary">
          {/* <h1 className="font-weight-normal mb-2" style={{ fontSize: "36px" }}>Hello,</h1> */}
          <h5 className="font-weight-normal mb-0">We're {(action === 'approve') ? 'approving' : 'rejecting'} the application...</h5>
        </div>
        <div className="text-left mb-0 mt-0">
          <p className="text-dark-50 font-weight-normal">Please be patient.</p>
        </div>
        <div className="">
          <svg className="splash-spinner" viewBox="0 0 50 50" style={{ marginLeft: "-25px" }}>
            <circle className="path" cx="25" cy="25" r="10" fill="none" strokeWidth="2"></circle>
          </svg>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(VerifyInstitution));
